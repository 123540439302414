:root {
  --border-gray: #b7bbbd;
  --yellow-dark: #fac100;
  --section-gap: 6px;

  --tomato: #ff6347;
  --green: #94e238;
  --beige: #947334;
  --brown: #563806;
}

html,
body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

#root {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  background-color: #eee;
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li,
a {
  margin: 0;
  padding: 0;
}
li,
li > a {
  text-decoration: none;
  list-style: none;
  color: #000;
}

input {
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 0 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
}
input::placeholder {
  color: var(--border-gray);
}
input:focus {
  outline: none;
  border-color: #000;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 400px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;

  resize: none;
}
textarea::placeholder {
  color: var(--border-gray);
}
textarea:focus {
  outline: none;
  border-color: #000;
}

.text-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: start;
}

/* modal */
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000aa;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-visible {
  display: flex;
}
.modal-base-inner {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  padding: 40px 68px;
  min-width: 480px;
  max-height: 100%;
  overflow-y: auto;
}
.modal-base-inner::-webkit-scrollbar {
  appearance: none;
}
.modal-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: center;
}

/* button */
.button-base {
  width: 100%;
  height: 52px;
  background-color: var(--yellow-dark);
  border-radius: 8px;
  cursor: pointer;

  font-size: 24px;
  font-weight: 700;
  line-height: 28.64px;
  color: #fff;
}
.button-base-outline {
  width: 100%;
  height: 52px;
  background-color: #fff;
  border: 1px solid var(--yellow-dark);
  border-radius: 8px;
  cursor: pointer;

  font-size: 24px;
  font-weight: 400;
  line-height: 28.64px;
  color: var(--yellow-dark);
}
.button-base-disabled {
  background-color: var(--border-gray);
  color: #fff;
}
.button-base-outline-disabled {
  border-color: var(--border-gray);
  color: var(--border-gray);
}

.snb-wrapper {
  width: 300px;
  height: 100%;
  margin-right: var(--section-gap);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

ul.snb-container {
  flex: 1;
  overflow-y: auto;
}
ul.snb-container::-webkit-scrollbar {
  appearance: none;
}

.snb-category {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
  color: var(--border-gray);
  margin-left: 30px;
}

.snb-menu {
  /* padding: 10px 0; */
}
.snb-menu > a {
  display: block;
  padding: 10px 30px;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  color: #000;
}

header {
  height: 120px;
  margin-bottom: var(--section-gap);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
}

.button-hover:hover {
  background-color: #fafafa !important;
}

.btn-small {
  height: 32px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.flex-row {
  display: flex;
}
.flex-row-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-col-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

main {
  padding: 40px;
  background-color: #fff;
  flex: 1;
  overflow: auto;
}

.search-container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-right: 22px;

  border: 1px solid var(--yellow-dark);
  border-radius: 8px;

  min-width: 400px;
}

.select-container {
  position: relative;
  /* min-width: 60px; */
  height: 48px;
  display: flex;
  align-items: center;

  border: 1px solid var(--yellow-dark);
  border-radius: 8px;

  overflow-y: hidden;
}
select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  min-width: 100px;
  height: 100%;
  outline: none;
  border: none;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: var(--yellow-dark);

  padding: 0 20px;
}
.search-container > .select-container {
  border: none;
  border-right: 1px solid var(--yellow-dark);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-container > .select-container > select {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;

  padding: 0 20px;
}
.select-container > span {
  position: absolute;
  right: 8px;
}

.search-container > .search-input {
  flex: 1;
  border: none;
  height: 48px;
  margin: 0 14px;
  padding: 0;
}
/* 검색아이콘 */
.search-container > img {
  width: 22px;
  height: 22px;
}

/* 테이블 */
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}
thead {
  width: 100%;
  height: 80px;
  background-color: var(--yellow-dark);
  margin-bottom: 20px;
}
tbody {
  width: 100%;
}
th {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  text-align: center;
}
th.small {
  font-size: 20px;
  line-height: 23.87px;
}
td {
  height: 50px;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  text-align: center;
}
td.small {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
}
td > p.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.pagination-wrapper {
  margin: 20px 0;
}
.pagination-wrapper button.pagination-prev,
.pagination-wrapper button.pagination-next {
  padding: 0;
  width: 36px;
  height: 36px;
  background-color: #fff;
}
.pagination-wrapper .pagination-prev > img,
.pagination-wrapper .pagination-next > img {
  width: 100%;
  height: 100%;
}
.pagination-wrapper button.pagination-num {
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;

  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: center;

  margin: 0 4px;
}
.pagination-wrapper button.pagination-num-current {
  background-color: var(--yellow-dark);
  cursor: default;
}

/* 사무소 검색 결과 */
.search-office-result-wrapper {
  height: 320px;
  overflow-y: auto;
}
.search-office-result {
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 8px;

  cursor: pointer;
}
.search-office-result-selected {
  border-color: var(--yellow-dark);
}
.search-office-result-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: left;
  margin-bottom: 4px;
}
.search-office-result-address {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: left;
}

p.label-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: center;
}
p.regular {
  font-weight: 400;
}
p.value-big {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
}
p.value-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
}
p.username {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  color: var(--brown);
}

/* switch */
.switch-cointainer {
  width: 128px;
  border: 1px solid var(--yellow-dark);
  border-radius: 8px;
  height: 32px;

  overflow: hidden;
}
.switch-cointainer > span {
  flex: 1;
  height: 100%;

  font-size: 12px;
  font-weight: 400;
  text-align: center;

  cursor: pointer;
}
.switch-off {
  border-color: var(--tomato);
  color: var(--tomato);
}

.switch-on > span:nth-child(1) {
  background-color: var(--yellow-dark);
  color: #fff;
}
.switch-on > span:nth-child(2) {
  color: var(--yellow-dark);
}
.switch-off > span:nth-child(1) {
  color: var(--tomato);
}
.switch-off > span:nth-child(2) {
  background-color: var(--tomato);
  color: #fff;
}

/* checkbox */
.checkbox-container {
  cursor: pointer;
}
.checkbox-container > span.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid var(--yellow-dark);
  border-radius: 4px;
  background-color: #fff;
  margin-right: 8px;
}
.checkbox-container > span.checkbox.checked {
  background-color: var(--yellow-dark);
}
.checkbox-container > span.label {
}

.folder-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  text-align: center;
}

.file-input-container {
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 0 10px 0 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.file-input-container > input[type="file"] {
  position: absolute;
  top: -1px;
  left: -1px;
  border: none;
  width: 1px;
  height: 1px;

  pointer-events: none;
}
